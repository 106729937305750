//Import Splide js
import Splide from '@splidejs/splide';

const options = {
	type: 'slide',
	perPage: 1,
	gap: 20,
    arrows: false,
	pagination: true,
	drag:true,
    mediaQuery: 'min',
    breakpoints: {
        901: {
            destroy: true,
        },
        500: {
			perPage: 2
		},
    }                   
}

export default class SplideGallery {    

    static membersGallery(target){
        let splide =  new Splide( target , options)
        splide.mount();
    } 
    static partnersGallery(target){
        console.log('partners gallery');
        let splideP =  new Splide( target , {
            type: 'slide',
            perPage: 5,
            gap: 20,
            arrows: false,
            pagination: true,
            drag:true,
            breakpoints: {   
                900: {
                    perPage: 4
                },    
                768: {
                    perPage: 3
                },        
                500: {
                    perPage: 2
                },
            }                   
        })
        splideP.mount();
    } 
       
    		
}