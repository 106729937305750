var player;
var done = false;

export default class IoYoutube {    
    static showVideo(target){
        console.debug('[start youtube function]');
        const yt_id = target.dataset.ytid;

        if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
            
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            window.onYouTubePlayerAPIReady = function() {
                IoYoutube.onYouTubePlayer(yt_id);
            };
        } else {
            IoYoutube.onYouTubePlayer(yt_id);
        }
        
    }   

    static onYouTubePlayer(video_id) {
        player = new YT.Player('player', {
            /*height: '720',*/
            width: '1280',
            videoId: video_id, 
            events: {
                'onReady': IoYoutube.onPlayerReady,
                'onStateChange': IoYoutube.onPlayerStateChange,
                'onError': function(errEvent) {
                                var errCode = errEvent.data;
                                console.log(errCode);
                            }
            }
        });
    }

    // 4. The API will call this function when the video player is ready.
   static onPlayerReady(event) {
        //event.target.playVideo();
    }

      // 5. The API calls this function when the player's state changes.
      //    The function indicates that when playing a video (state=1),
      //    the player should play for six seconds and then stop.      
      static onPlayerStateChange(event) {
        if (event.data == YT.PlayerState.PLAYING && !done) {
          setTimeout(IoYoutube.stopVideo, 6000);
          done = true;
        }
        console.log(event);
      }
      static stopVideo() {
        player.stopVideo();
      }
}