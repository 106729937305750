const menuToggler = document.querySelector('.hamburger');
const menu = document.querySelector('#f-menu');

export default class MenuToggler {    

    static toggleMenu = (function() {
        menu.classList.contains('menu-fullpage-active') ? (
                                                            menu.classList.remove('menu-fullpage-active'),
                                                            menuToggler.classList.remove('hamburger-active'),
                                                            document.body.classList.remove('menu-open')//style.overflowX = 'auto' 
                                                       ) : (
                                                            menu.classList.add('menu-fullpage-active'),
                                                            menuToggler.classList.add('hamburger-active'),
                                                            document.body.classList.add('menu-open')//style.overflow = 'hidden'
                                                       )
    })

    static init(){
        menuToggler.addEventListener('click', this.toggleMenu, false);
    }    
    
}