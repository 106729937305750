const rootElement = document.documentElement
const scrollToTopBtn = document.querySelector('#topBtn');

export default class ScrollToTop { 
    static backToTop() {
        // Scroll to top logic
        rootElement.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    static handleScroll() {
        // Do something on scroll
        var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
        if (rootElement.scrollTop > 200 ) {
            // Show button
            scrollToTopBtn.classList.add('showBtn')
        } else {
            // Hide button
            scrollToTopBtn.classList.remove('showBtn')
        }
    }
    static init(){
        scrollToTopBtn.addEventListener("click", this.backToTop)
        document.addEventListener('scroll', this.handleScroll, false);
    }        
}