const back_link = document.querySelector('#backLink');

export default class GoBack {    

    static init(){
        if(typeof back_link !== 'undefined' && back_link !== null){ 
            back_link.addEventListener('click', e => {
                e.preventDefault()
                history.back();
            })
        }
    }    
    
}