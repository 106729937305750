//import css Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
// Default CSS theme splide
import '@splidejs/splide/css';
//import Sass css
import "../sass/master.scss";
//import yii css
import "../css/site.css";
// BOOtstrap plugins You can specify which plugins you need
import { TABS } from 'bootstrap';
//import * as bootstrap from 'bootstrap'

import MenuToggler from '../js/menuToggler.js';
import IoYoutube from '../js/youtube.js';
import LogoCss from '../js/logoCss.js';
import SplideGallery from '../js/splideGallery.js';
import ScrollToTop from '../js/scrollToTop.js';
import GoBack from '../js/goBack.js';

//MENU TOGGLER
MenuToggler.init();
/* END MENU TOGGLER */ 

/* GESTIONE CLASSI CSS LOGO */
const target_class = document.querySelector('#dave');
if(target_class){    
    LogoCss.init(target_class);
}

/* START GESTIONE SPLIDE */
const members = document.querySelector('#membersGallery');
const partners = document.querySelector('#partnersGallery');
if(members){ 
    SplideGallery.membersGallery(members);
}
if(partners){ 
    SplideGallery.partnersGallery(partners);
}
/* END GESTIONE SPLIDE */

//YOUTUBE VIDEO
const target_youtube = document.querySelector('#player');
if(target_youtube){    
    IoYoutube.showVideo(target_youtube);
}
/* END GESTIONE YOUTUBE */ 

/* SCROLL TO TOP  */
ScrollToTop.init();

//BACK LINK
GoBack.init();


/* Cookie Policy */ 
(function(w, d) {
    var loader = function() {
        var s = d.createElement("script"),
        tag = d.getElementsByTagName("script")[0];
        s.src = "https://cdn.iubenda.com/iubenda.js";
        tag.parentNode.insertBefore(s, tag);
    };
    if (w.addEventListener) {
        w.addEventListener("load", loader, false);
    } else if (w.attachEvent) {
        w.attachEvent("onload", loader);
    } else {
        w.onload = loader;
    }
})(window, document);

/*- Privacy */ 
(function(w, d) {
    var loader = function() {
        var s = d.createElement("script"),
            tag = d.getElementsByTagName("script")[0];
        s.src = "https://cdn.iubenda.com/iubenda.js";
        tag.parentNode.insertBefore(s, tag);
    };
    if (w.addEventListener) {
        w.addEventListener("load", loader, false);
    }else if (w.attachEvent) {
        w.attachEvent("onload", loader);
    }else {
        w.onload = loader;
    }
})(window, document); 

/* Mutuation Observer  */
function waitForElement(querySelector, timeout){
  return new Promise((resolve, reject)=>{
    var timer = false;
    if(document.querySelectorAll(querySelector).length) return resolve();
    const observer = new MutationObserver(()=>{
      if(document.querySelectorAll(querySelector).length){
        observer.disconnect();
        if(timer !== false) clearTimeout(timer);
        return resolve();
      }
    });
    observer.observe(document.body, {
      childList: true, 
      subtree: true
    });
    if(timeout) timer = setTimeout(()=>{
      observer.disconnect();
      reject();
    }, timeout);
  });
}

var fbBtn = document.querySelector( '.juicer-aft' );
if(fbBtn){
    fbBtn.parentNode.removeChild( fbBtn );
}


waitForElement(".j-stacker-wrapper", 1000).then(function(){
    let wrap = document.querySelector('.j-stacker-wrapper');
    if(fbBtn){
        wrap.after(fbBtn);
    }
    
}).catch((e)=>{
    //console.log(e);
});